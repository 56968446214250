@import "colors"
@import "font"

.CodeMirror
    font-family: $default_font
    height: 100%

.CodeMirror-lines
    padding: 0
    margin-top: 2px

.cm-s-default.CodeMirror, .cm-s-default .CodeMirror-gutters
    background-color: $main-bg !important
    color: $text-basic !important
    border: none

.cm-s-default .CodeMirror-gutters
    color: #282a36

.cm-s-default .CodeMirror-cursor
    border-left: solid thin #f8f8f0

.cm-s-default .CodeMirror-linenumber
    color: $gutter-text
    background-color: $gutter-bg
    text-align: left
    padding-right: 24px
    padding-left: 16px

.cm-s-default .CodeMirror-selected
    background: rgba(255, 255, 255, 0.10)

.cm-s-default .CodeMirror-line::selection, .cm-s-default .CodeMirror-line > span::selection, .cm-s-default .CodeMirror-line > span > span::selection
    background: rgba(255, 255, 255, 0.10)

.cm-s-default .CodeMirror-line::-moz-selection, .cm-s-default .CodeMirror-line > span::-moz-selection, .cm-s-default .CodeMirror-line > span > span::-moz-selection
    background: rgba(255, 255, 255, 0.10)

.cm-s-default span.cm-comment
    color: $editor-comment

.cm-s-default span.cm-string, .cm-s-default span.cm-string-2
    color: $editor-comment

.cm-s-default span.cm-number
    color: $editor-number

.cm-s-default span.cm-variable
    color: $editor-var

.cm-s-default span.cm-variable-2
    color: $editor-var

.cm-s-default span.cm-def
    color: $editor-def

.cm-s-default span.cm-operator
    color: $editor-operator

.cm-s-default span.cm-keyword
    color: $editor-keyword

.cm-s-default span.cm-atom
    color: $editor-atom

.cm-s-default span.cm-meta
    color: #f8f8f2

.cm-s-default span.cm-tag
    color: #ff79c6

.cm-s-default span.cm-attribute
    color: #50fa7b

.cm-s-default span.cm-qualifier
    color: #50fa7b

.cm-s-default span.cm-property
    color: #66d9ef

.cm-s-default span.cm-builtin
    color: #50fa7b

.cm-s-default span.cm-variable-3, .cm-s-default span.cm-type
    color: #ffb86c

.cm-s-default .CodeMirror-activeline-background
    background: $editor-activeline

.cm-s-default .CodeMirror-matchingbracket
    text-decoration: underline
    color: $text-basic !important
