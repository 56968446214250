@import "colors"

.Tabs
    display: flex
    flex-direction: row
    background-color: $main-border

.Tabs .HiddenTabs
    background-color: $main-fg
    border-top: 4px $main-fg solid
    border-bottom: 4px $main-fg solid
    padding: 0 4px
    cursor: pointer

.Tabs .HiddenTabs .HiddenTabList
    position: absolute
    display: flex
    flex-direction: column
    background-color: $main-fg
    z-index: 999
    border: 2px $main-border solid

.Tabs .HiddenTabs .HiddenTabList div
    cursor: pointer
    padding: 4px
    border-bottom: 2px $main-border solid

.Tabs .HiddenTabs .HiddenTabList div:last-child
    border-bottom: 0

.Tabs .HiddenTabs .HiddenTabList div img
    margin-right: 4px
    margin-bottom: 2px

.Tabs .HiddenTabs .HiddenTabList div:hover
    background-color: $focus-light

.Tabs .TabList
    display: flex
    flex-direction: row

    overflow: hidden
    margin-right: 2px

.Tabs .TabList .Tab
    display: flex
    flex-direction: row
    align-items: center
    align-self: center

    cursor: pointer
    background-color: $main-fg
    margin-right: 2px
    border-top: 4px $main-fg solid
    border-bottom: 4px $main-fg solid

.Tabs .TabList .Tab:last-child
    margin-right: 0

.Tabs .TabList .Tab:hover
    background-color: $focus-light
    border-top: 4px $focus-light solid
    border-bottom: 4px $focus-light solid

.Tabs .TabList .Tab.focused
    background-color: $focus-light
    color: $text-focus
    border-top: 4px $focus-light solid
    border-bottom: 4px $focus-blue solid

.Tabs .TabList .Tab .TabImage
    margin: 0 8px
    height: 16px

.Tabs .Tab .TabTitle
    margin-right: 8px
