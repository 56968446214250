html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden, [hidden] {
  display: none !important;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only.focusable:active, .sr-only.focusable:focus {
  clip: auto;
  height: auto;
  white-space: inherit;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media print {
  *, :before, :after {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: #fff !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }
}

.CodeMirror {
  height: 300px;
  color: #000;
  direction: ltr;
}

.CodeMirror-lines {
  padding: 4px 0;
}

.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px;
}

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  background-color: #fff;
}

.CodeMirror-gutters {
  white-space: nowrap;
  background-color: #f7f7f7;
  border-right: 1px solid #ddd;
}

.CodeMirror-linenumber {
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
  padding: 0 3px 0 5px;
}

.CodeMirror-guttermarker {
  color: #000;
}

.CodeMirror-guttermarker-subtle {
  color: #999;
}

.CodeMirror-cursor {
  width: 0;
  border-left: 1px solid #000;
  border-right: none;
}

.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}

.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  background: #7e7;
  border: 0 !important;
}

.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}

.cm-fat-cursor-mark {
  background-color: #14ff1480;
  animation: blink 1.06s step-end infinite;
}

.cm-animate-fat-cursor {
  width: auto;
  background-color: #7e7;
  border: 0;
  animation: blink 1.06s step-end infinite;
}

@keyframes blink {
  0% {
  }

  50% {
    background-color: #0000;
  }

  100% {
  }
}

.cm-tab {
  text-decoration: inherit;
  display: inline-block;
}

.CodeMirror-rulers {
  position: absolute;
  inset: -50px 0 0;
  overflow: hidden;
}

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  position: absolute;
  top: 0;
  bottom: 0;
}

.cm-s-default .cm-header {
  color: #00f;
}

.cm-s-default .cm-quote {
  color: #090;
}

.cm-negative {
  color: #d44;
}

.cm-positive {
  color: #292;
}

.cm-header, .cm-strong {
  font-weight: bold;
}

.cm-em {
  font-style: italic;
}

.cm-link {
  text-decoration: underline;
}

.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-keyword {
  color: #708;
}

.cm-s-default .cm-atom {
  color: #219;
}

.cm-s-default .cm-number {
  color: #164;
}

.cm-s-default .cm-def {
  color: #00f;
}

.cm-s-default .cm-variable-2 {
  color: #05a;
}

.cm-s-default .cm-variable-3, .cm-s-default .cm-type {
  color: #085;
}

.cm-s-default .cm-comment {
  color: #a50;
}

.cm-s-default .cm-string {
  color: #a11;
}

.cm-s-default .cm-string-2 {
  color: #f50;
}

.cm-s-default .cm-meta, .cm-s-default .cm-qualifier {
  color: #555;
}

.cm-s-default .cm-builtin {
  color: #30a;
}

.cm-s-default .cm-bracket {
  color: #997;
}

.cm-s-default .cm-tag {
  color: #170;
}

.cm-s-default .cm-attribute {
  color: #00c;
}

.cm-s-default .cm-hr {
  color: #999;
}

.cm-s-default .cm-link {
  color: #00c;
}

.cm-s-default .cm-error, .cm-invalidchar {
  color: red;
}

.CodeMirror-composing {
  border-bottom: 2px solid;
}

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}

.CodeMirror-matchingtag {
  background: #ff96004d;
}

.CodeMirror-activeline-background {
  background: #e8f2ff;
}

.CodeMirror {
  background: #fff;
  position: relative;
  overflow: hidden;
}

.CodeMirror-scroll {
  height: 100%;
  outline: none;
  margin-bottom: -50px;
  margin-right: -50px;
  padding-bottom: 50px;
  position: relative;
  overflow: scroll !important;
}

.CodeMirror-sizer {
  border-right: 50px solid #0000;
  position: relative;
}

.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  z-index: 6;
  outline: none;
  display: none;
  position: absolute;
}

.CodeMirror-vscrollbar {
  top: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-x: scroll;
  overflow-y: hidden;
}

.CodeMirror-scrollbar-filler {
  bottom: 0;
  right: 0;
}

.CodeMirror-gutter-filler {
  bottom: 0;
  left: 0;
}

.CodeMirror-gutters {
  min-height: 100%;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}

.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  vertical-align: top;
  margin-bottom: -50px;
  display: inline-block;
}

.CodeMirror-gutter-wrapper {
  z-index: 4;
  position: absolute;
  background: none !important;
  border: none !important;
}

.CodeMirror-gutter-background {
  z-index: 4;
  position: absolute;
  top: 0;
  bottom: 0;
}

.CodeMirror-gutter-elt {
  cursor: default;
  z-index: 4;
  position: absolute;
}

.CodeMirror-gutter-wrapper ::-moz-selection {
  background-color: #0000;
}

.CodeMirror-gutter-wrapper ::selection {
  background-color: #0000;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px;
}

.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  font-family: inherit;
  font-size: inherit;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
  background: none;
  border-width: 0;
  border-radius: 0;
  margin: 0;
  position: relative;
  overflow: visible;
}

.CodeMirror-wrap pre.CodeMirror-line, .CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  z-index: 0;
  position: absolute;
  inset: 0;
}

.CodeMirror-linewidget {
  z-index: 2;
  padding: .1px;
  position: relative;
}

.CodeMirror-rtl pre {
  direction: rtl;
}

.CodeMirror-code {
  outline: none;
}

.CodeMirror-scroll, .CodeMirror-sizer, .CodeMirror-gutter, .CodeMirror-gutters, .CodeMirror-linenumber {
  box-sizing: content-box;
}

.CodeMirror-measure {
  width: 100%;
  height: 0;
  visibility: hidden;
  position: absolute;
  overflow: hidden;
}

.CodeMirror-cursor {
  pointer-events: none;
  position: absolute;
}

.CodeMirror-measure pre {
  position: static;
}

div.CodeMirror-cursors {
  visibility: hidden;
  z-index: 3;
  position: relative;
}

div.CodeMirror-dragcursors, .CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}

.CodeMirror-crosshair {
  cursor: crosshair;
}

.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}

.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}

.cm-searching {
  background-color: #ff06;
}

.cm-force-border {
  padding-right: .1px;
}

@media print {
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

.cm-tab-wrap-hack:after {
  content: "";
}

span.CodeMirror-selectedtext {
  background: none;
}

#Starfield {
  position: fixed;
  top: 0;
  left: 0;
}

.CodeMirror {
  height: 100%;
  font-family: courier, monospace;
}

.CodeMirror-lines {
  margin-top: 2px;
  padding: 0;
}

.cm-s-default.CodeMirror, .cm-s-default .CodeMirror-gutters {
  border: none;
  color: #bbb !important;
  background-color: #282c34 !important;
}

.cm-s-default .CodeMirror-gutters {
  color: #282a36;
}

.cm-s-default .CodeMirror-cursor {
  border-left: thin solid #f8f8f0;
}

.cm-s-default .CodeMirror-linenumber {
  color: #474f60;
  text-align: left;
  background-color: #282c34;
  padding-left: 16px;
  padding-right: 24px;
}

.cm-s-default .CodeMirror-selected, .cm-s-default .CodeMirror-line::selection, .cm-s-default .CodeMirror-line > span::selection, .cm-s-default .CodeMirror-line > span > span::selection {
  background: #ffffff1a;
}

.cm-s-default .CodeMirror-line::-moz-selection, .cm-s-default .CodeMirror-line > span::-moz-selection, .cm-s-default .CodeMirror-line > span > span::-moz-selection {
  background: #ffffff1a;
}

.cm-s-default span.cm-comment, .cm-s-default span.cm-string, .cm-s-default span.cm-string-2 {
  color: #7cc963;
}

.cm-s-default span.cm-number {
  color: #b49956;
}

.cm-s-default span.cm-variable, .cm-s-default span.cm-variable-2 {
  color: #bbb;
}

.cm-s-default span.cm-def {
  color: #2bbac5;
}

.cm-s-default span.cm-operator {
  color: #bbb;
}

.cm-s-default span.cm-keyword {
  color: #d55fde;
}

.cm-s-default span.cm-atom {
  color: #bbb;
}

.cm-s-default span.cm-meta {
  color: #f8f8f2;
}

.cm-s-default span.cm-tag {
  color: #ff79c6;
}

.cm-s-default span.cm-attribute, .cm-s-default span.cm-qualifier {
  color: #50fa7b;
}

.cm-s-default span.cm-property {
  color: #66d9ef;
}

.cm-s-default span.cm-builtin {
  color: #50fa7b;
}

.cm-s-default span.cm-variable-3, .cm-s-default span.cm-type {
  color: #ffb86c;
}

.cm-s-default .CodeMirror-activeline-background {
  background: #2c313c;
}

.cm-s-default .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: #bbb !important;
}

html body {
  color: #bbb;
  background-color: #282c34;
  margin: auto;
  font-family: courier, monospace;
  font-size: 18px;
}

a {
  text-decoration: none;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

h1, .size48 {
  font-size: 48px;
}

h2, .size36 {
  font-size: 36px;
}

h3, .size24 {
  font-size: 24px;
}

h4, .size18 {
  font-size: 18px;
}

.line48 {
  line-height: 48px;
}

.line36 {
  line-height: 36px;
}

.line24 {
  line-height: 24px;
}

.line18 {
  line-height: 18px;
}

.line0 {
  line-height: 0;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

#Container {
  max-width: 864px;
  min-width: 864px;
  width: 864px;
  margin: 0 auto;
  animation-name: fadeInTop;
  animation-duration: .5s;
  position: relative;
}

#Contact {
  position: absolute;
  top: 0;
  right: 0;
}

.Section {
  background-color: #282c34;
}

.text-keyword {
  color: #d55fde;
}

.text-property {
  color: #2bbac5;
}

.comment {
  color: #7cc963;
}

.decorator {
  color: #ff0;
}

.Tabs {
  background-color: #21252b;
  flex-direction: row;
  display: flex;
}

.Tabs .HiddenTabs {
  cursor: pointer;
  background-color: #21252b;
  border-top: 4px solid #21252b;
  border-bottom: 4px solid #21252b;
  padding: 0 4px;
}

.Tabs .HiddenTabs .HiddenTabList {
  z-index: 999;
  background-color: #21252b;
  border: 2px solid #21252b;
  flex-direction: column;
  display: flex;
  position: absolute;
}

.Tabs .HiddenTabs .HiddenTabList div {
  cursor: pointer;
  border-bottom: 2px solid #21252b;
  padding: 4px;
}

.Tabs .HiddenTabs .HiddenTabList div:last-child {
  border-bottom: 0;
}

.Tabs .HiddenTabs .HiddenTabList div img {
  margin-bottom: 2px;
  margin-right: 4px;
}

.Tabs .HiddenTabs .HiddenTabList div:hover {
  background-color: #3d424b;
}

.Tabs .TabList {
  flex-direction: row;
  margin-right: 2px;
  display: flex;
  overflow: hidden;
}

.Tabs .TabList .Tab {
  cursor: pointer;
  background-color: #21252b;
  border-top: 4px solid #21252b;
  border-bottom: 4px solid #21252b;
  flex-direction: row;
  align-self: center;
  align-items: center;
  margin-right: 2px;
  display: flex;
}

.Tabs .TabList .Tab:last-child {
  margin-right: 0;
}

.Tabs .TabList .Tab:hover {
  background-color: #3d424b;
  border-top: 4px solid #3d424b;
  border-bottom: 4px solid #3d424b;
}

.Tabs .TabList .Tab.focused {
  color: #fff;
  background-color: #3d424b;
  border-top: 4px solid #3d424b;
  border-bottom: 4px solid #4269b9;
}

.Tabs .TabList .Tab .TabImage {
  height: 16px;
  margin: 0 8px;
}

.Tabs .Tab .TabTitle {
  margin-right: 8px;
}

.Files {
  width: 128px;
  height: 100%;
  border-left: 2px solid #21252b;
  flex-direction: column;
  display: flex;
}

.Files .FilesTitle {
  color: #bbb;
  background-color: #323844;
  border-top: 4px solid #0000;
  border-bottom: 2px solid #21252b;
  padding-bottom: 4px;
  padding-left: 8px;
}

.Files .FilesList {
  height: 100%;
  background-color: #21252b;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  display: flex;
}

.Files .FilesList .FilesImage:first-child {
  margin-top: 11px;
}

.Files .FilesList .FilesImage {
  width: 80%;
  cursor: pointer;
  border-bottom: 3px solid #21252b;
  margin-top: 8px;
  transition: all .1s;
}

.Files .FilesList .FilesImage:hover {
  border-bottom: 3px solid #5f5f5f;
}

.Window {
  max-width: 800px;
  max-height: 600px;
  z-index: 1000;
  background-color: #21252b;
  border: 1px solid #5f5f5f;
  flex-direction: column;
  display: flex;
  position: absolute;
}

.Window .Header {
  max-height: 28px;
  min-height: 28px;
  height: 28px;
  cursor: move;
  background-color: #323844;
  border-bottom: 1px solid #5f5f5f;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Window .Header .WindowTitle {
  cursor: default;
  margin-left: 8px;
  font-weight: bold;
}

.Window .Header img {
  float: left;
  cursor: pointer;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 6px;
  padding: 2px;
}

.Window .Header img:hover {
  background-color: #3d424b;
}

.Window .Actions {
  max-height: 28px;
  min-height: 28px;
  height: 28px;
  background-color: #21252b;
  border-bottom: 1px solid #5f5f5f;
  place-content: center left;
  display: flex;
}

.Window .Actions img {
  cursor: pointer;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 6px;
  padding: 2px;
}

.Window .Actions img:hover {
  background-color: #3d424b;
}

.Window .ImageContainer {
  display: flex;
  overflow: scroll;
}

.Window .ImageContainer img, .Window .ImageContainer iframe, .Window .ImageContainer video {
  margin: auto;
}

.Window .Footer {
  max-height: 96px;
  min-height: 96px;
  height: 96px;
  flex-direction: column;
  display: flex;
  overflow-y: scroll;
}

.Window .Footer .ImageDescription {
  color: #7cc963;
  background-color: #282c34;
  margin: 8px;
}

/*# sourceMappingURL=index.9e186524.css.map */
