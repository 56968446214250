@import "colors"

.Files
    display: flex
    flex-direction: column

    width: 128px
    height: 100%

    border-left: 2px $main-border solid

.Files .FilesTitle
    background-color: $focus-dark
    color: $text-basic
    border-top: 4px transparent solid
    padding-bottom: 4px
    border-bottom: 2px $main-border solid
    padding-left: 8px


.Files .FilesList
    display: flex
    flex-direction: column
    justify-content: start
    align-items: center
    height: 100%
    background-color: $main-fg

.Files .FilesList .FilesImage:first-child
    margin-top: 11px

.Files .FilesList .FilesImage
    width: 80%
    margin-top: 8px
    cursor: pointer
    transition: all 0.1s
    border-bottom: 3px $main-fg solid

.Files .FilesList .FilesImage:hover
    border-bottom: 3px $main-border1 solid

.Window
    position: absolute
    display: flex
    flex-direction: column

    background-color: $main-fg
    border: 1px $main-border1 solid
    max-width: 800px
    max-height: 600px
    z-index: 1000
//    animation-name: fadeIn
    animation-duration: 0.1s
    animation-timing-function: ease-out

.Window .Header
    display: flex
    align-items: center
    justify-content: space-between
    max-height: 28px
    min-height: 28px
    height: 28px
    background-color: $focus-dark
    border-bottom: 1px $main-border1 solid
    cursor: move

.Window .Header .WindowTitle
    font-weight: bold
    margin-left: 8px
    cursor: default

.Window .Header img
    padding: 2px
    margin-right: 6px
    margin-top: 2px
    margin-bottom: 2px
    float: left
    cursor: pointer

.Window .Header img:hover
    background-color: $focus-light


.Window .Actions
    display: flex
    align-content: center
    justify-content: left
    max-height: 28px
    min-height: 28px
    height: 28px
    background-color: $main-fg
    border-bottom: 1px $main-border1 solid

.Window .Actions img
    cursor: pointer
    padding: 2px
    margin-left: 6px
    margin-top: 2px
    margin-bottom: 2px

.Window .Actions img:hover
    background-color: $focus-light

.Window .ImageContainer
    display: flex
    overflow: scroll

.Window .ImageContainer img
    margin: auto

.Window .ImageContainer iframe
    margin: auto

.Window .ImageContainer video 
    margin: auto

.Window .Footer
    display: flex
    flex-direction: column
    max-height: 96px
    min-height: 96px
    height: 96px
    overflow-y: scroll

.Window .Footer .ImageDescription
    margin: 8px
    color: $editor-comment
    background-color: $main-bg
