$main-bg: #282C34
$main-fg: #21252B
$main-border: #21252B
$main-border1: #5F5F5F

$focus-dark: #323844
$focus-blue: #4269B9
$focus-light: #3D424B

$tab-focus: #414855

$text-basic: #BBBBBB
$text-focus: #FFFFFF

$gutter-text: #474F60
$gutter-bg: #282C34

$editor-keyword: #D55FDE // "let" foo = baz; "return", "function"(){}, "instanceof", etc.
$editor-number: #B49956
$editor-comment: #7CC963
$editor-normal: #BBBBBB
$editor-def: #2BBAC5 // let "foo" = baz;
$editor-var: #BBBBBB // foo instanceof "HTMLElement"
$editor-property: #BBBBBB // foo."property"
$editor-operator: #BBBBBB // = < > etc.
$editor-atom: #BBBBBB // true, false
$editor-highlight: #2C313C

$editor-activeline: #2C313C
