@import "./normalize.css"
@import "./default.css"
@import "./codemirror.css"

@import "colors"
@import "starfield"
@import "codemirror_theme"
@import "font"

html body
    background-color: $main-bg
    font-family: $default_font
    font-size: 18px
    color: $text-basic
    margin: auto

a
    text-decoration: none

@keyframes fadeInRight
    0%
        opacity: 0
        transform: translateX(50px)
    100%
        opacity: 1
        transform: translateX(0px)

@-webkit-keyframes fadeInRight
    0%
        opacity: 0
        transform: translateX(50px)
    100%
        opacity: 1
        transform: translateX(0px)

@keyframes fadeInTop
    0%
        opacity: 0
        transform: translateY(-50px)
    100%
        opacity: 1
        transform: translateY(0px)

@-webkit-keyframes fadeInTop
    0%
        opacity: 0
        transform: translateY(-50px)
    100%
        opacity: 1
        transform: translateY(0px)


@keyframes fadeIn
    0%
        opacity: 0
    100%
        opacity: 1

@-webkit-keyframes fadeIn
    0%
        opacity: 0
    100%
        opacity: 1

h1, h2, h3, h4, h5, h6
    font-weight: 400

h1, .size48
    font-size: 48px

h2, .size36
    font-size: 36px

h3, .size24
    font-size: 24px

h4, .size18
    font-size: 18px

.line48
    line-height: 48px

.line36
    line-height: 36px

.line24
    line-height: 24px

.line18
    line-height: 18px

.line0
    line-height: 0

.left
    text-align: left

.right
    text-align: right

.center
    text-align: center

#Container
    position: relative
    max-width: 864px
    min-width: 864px
    width: 864px
    margin: 0 auto
    animation-name: fadeInTop
    animation-duration: 0.5s

#Contact
    position: absolute
    top: 0
    right: 0

.Section
    background-color: $main-bg

.text-keyword
    color: $editor-keyword

.text-property
    color: $editor-def

.comment
    color: $editor-comment

.decorator
    color: #ff0
